
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { showModal } from "@/helpers/callables";
import SaveView from "@/components/modal/SaveView.vue";
import { Action, Getter, Mutation, State } from "vuex-class";
import ViewFilter from "@/components/ViewFilter.vue";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import { IFilter, IItemFilter, LabelValue, StoreAction, StoreMutation } from "@/types";
import clonedeep from "lodash.clonedeep";
import { ROUTES } from "@/data";
import Dropdown from "./Dropdown.vue";

@Component({
    name: "ViewConfigurator",
    components: { ViewFilter, SaveView, Dropdown },
})
export default class ViewConfigurator extends mixins(VModel) {
    @Prop(Boolean) noSave!: boolean;
    @Prop(Boolean) isGanttMode!: boolean;
    @Prop(Boolean) isGestionInactifs!: boolean;
    @Prop() definedFilters!: any;
    @Prop() loading!: boolean;
    @Prop() visualisations!: any;

    @State("configuration") configuration!: any;
    @State("currentUser") currentUser!: any;
    @State("selectedView") selectedView!: any;
    @State("lastSelectedView") lastSelectedView!: any;
    @State("lastinnerValue") lastinnerValue!: any;
    @Action("actions/createPreconfiguredView") createPreconfiguredView!: StoreAction;
    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Getter("businessSectorOptions") businessSectorOptions!: LabelValue[];
    @Getter("interimStatusOptions") interimStatusOptions!: LabelValue[];
    @Getter("isInteraction") isInteraction!: any;
    @Getter("allUserViews") allUserViews!: any;
    @Getter("getJobOption") getJobOption!: any;
    @Getter("isAgency") isAgency!: any;
    @Getter("userContext") userContext!: string;
    @Getter("isArchives") isArchives!: any;
    @Getter("getFinalMissionStatus") getFinalMissionStatus!: any;
    @Mutation("setLastinnerValue") setLastinnerValue!: StoreMutation;
    @Mutation("setSelectedView") setSelectedView!: StoreMutation;

    showSaveView = false;
    enableScroll = true;
    innerLoading = false;

    @Watch("selectedView")
    onChangeView() {
        if (!this.innerLoading && this.selectedView) {
            let value = clonedeep(this.selectedView.filters);

            if (value.jobs) {
                if (Array.isArray(value.jobs)) {
                    this.$set(
                        value,
                        "jobs",
                        value.jobs.map((jobId: string) => this.getJobOption(jobId))
                    );
                } else if (typeof value.jobs === "string") {
                    this.$set(value, "jobs", [clonedeep(this.getJobOption(value.jobs))]);
                }
            } else {
                this.$set(value, "jobs", []);
            }
            if (value.businessSector) {
                if (Array.isArray(value.businessSector)) {
                    this.$set(
                        value,
                        "businessSector",
                        value.businessSector.map((bsId: string) => this.getBusinessSector(bsId))
                    );
                } else if (typeof value.businessSector === "string") {
                    this.$set(value, "businessSector", [clonedeep(this.getBusinessSector(value.businessSector))]);
                }
            } else {
                this.$set(value, "businessSector", []);
            }
            if (value.status) {
                if (Array.isArray(value.status)) {
                    this.$set(
                        value,
                        "status",
                        value.status.map((status: string) => this.getStatus(status))
                    );
                } else if (typeof value.status === "string") {
                    this.$set(value, "status", [clonedeep(this.getStatus(value.status))]);
                }
            } else {
                this.$set(value, "status", []);
            }
            if (value.missionStatus) {
                if (Array.isArray(value.missionStatus)) {
                    this.$set(
                        value,
                        "missionStatus",
                        value.missionStatus.map((statuses: string[]) => this.getFinalMissionStatus(statuses))
                    );
                }
            } else {
                this.$set(value, "missionStatus", []);
            }

            if (value.hasNightAvailable === true) {
                value.hasNightAvailable = "yes";
            }
            if (value.hasNightAvailable === false) {
                value.hasNightAvailable = "no";
            }
            if (value.hasWeekendAvailable === true) {
                value.hasWeekendAvailable = "yes";
            }
            if (value.hasWeekendAvailable === false) {
                value.hasWeekendAvailable = "no";
            }
            if (value.hasVehicle === true) {
                value.hasVehicle = "yes";
            }
            if (value.hasVehicle === false) {
                value.hasVehicle = "no";
            }
            if (value.withAlerts === true) {
                value.hasVehicle = "yes";
            }
            if (value.withAlerts === false) {
                value.hasVehicle = "no";
            }

            this.innerValue = value;
            this.$forceUpdate();
        }
    }

    get context() {
        return this.$route.name;
    }

    get isInterims() {
        return (
            this.context === ROUTES.APP.INTERIMS._ROOT ||
            this.context === ROUTES.APP.INTERIMS.LIST ||
            this.context === ROUTES.APP.INTERIMS.INVITATIONS ||
            this.context === ROUTES.APP.INTERIMS.AVAILABILITIES ||
            this.context === ROUTES.APP.INTERIMS.GESTION_INACTIFS
        );
    }

    get isFollowing() {
        return (
            this.context === ROUTES.APP.FOLLOWING._ROOT ||
            this.context === ROUTES.APP.FOLLOWING.ARCHIVE ||
            this.context === ROUTES.APP.FOLLOWING.GLOBAL
        );
    }

    get isPlanning() {
        return (
            this.context ||
            this.context === ROUTES.APP.PLANNING.ORDER ||
            this.context === ROUTES.APP.PLANNING.WORKERS ||
            this.context === ROUTES.APP.PLANNING.COMPANIES
        );
    }

    get isGanttWorkers() {
        return this.$route.query?.mode === "gantt-workers";
    }

    get isGanttClients() {
        return this.$route.query?.mode === "gantt-clients";
    }

    get isWeek() {
        return this.$route.query?.mode === "week";
    }

    get filters() {
        const filters = [...(this.definedFilters || this.configuration.views[<string>this.context]?.innerValue || [])];

        if (this.isArchives) {
            if (this.isAgency) {
                return ["businessSector", "client", "jobs"];
            } else {
                return ["agency", "jobs"];
            }
        }

        if (this.isPlanning && this.isGanttWorkers) {
            return ["search", "jobs", "client", "missionStatus"];
        }

        if (this.isInterims && this.isGanttMode) {
            return ["search", "jobs"];
        }

        if (this.isFollowing) {
            return ["client", "businessSector", "jobs", "search", "missionAlert", "contractType"];
        }

        if (this.isPlanning && (this.isWeek || this.isGanttClients)) {
            return [...filters, "missionStatus"];
        }

        return filters;
    }

    get filtersDropdown() {
        return [
            {
                sectionName: this.$t("layout.visualisations.action"),
                type: "checkbox",
                selectedAll: true,
                sectionIcon: "design-system-click-mouse",
                items: [
                    {
                        label: this.$t("layout.visualisations.incompleteProfile"),
                        value: "incomplete",
                        selected: this.innerValue.incomplete,
                    },
                    {
                        label: this.$t("layout.visualisations.askedChanges"),
                        value: "askedChanges",
                        selected: this.innerValue.askedChanges,
                    },
                ],
            },
            {
                sectionName: this.$t("layout.visualisations.profil"),
                type: "checkbox",
                selectedAll: false,
                sectionIcon: "design-system-user",
                items: [
                    {
                        label: this.$t("layout.visualisations.employed"),
                        value: "employed",
                        selected: this.innerValue.employed,
                    },
                    {
                        label: this.$t("layout.visualisations.candidate"),
                        value: "candidate",
                        selected: this.innerValue.candidate,
                    },
                    {
                        label: this.$t("layout.visualisations.availableProfiles"),
                        value: "available",
                        selected: this.innerValue.available,
                    },
                ],
            },
        ];
    }

    getView(slug: string) {
        return this.allUserViews(this.context).find((pv: any) => pv.nameSlug === slug);
    }

    getJob(id: string) {
        return this.jobOptions.find((i: LabelValue) => i.value === id);
    }

    getBusinessSector(id: string) {
        return this.businessSectorOptions.find((i: LabelValue) => i.value === id);
    }

    getStatus(status: string) {
        return this.interimStatusOptions.find((i: LabelValue) => i.value === status);
    }

    onInput(filter: string, value: any) {
        if (typeof value === "string") {
            this.$set(this.innerValue, filter, value);
        } else {
            if (Array.isArray(value)) {
                this.$set(this.innerValue, filter, (value?.length && value) || undefined);
            }
        }
        this.$forceUpdate();
    }
    openSave() {
        showModal.call(this, "SaveView");
    }

    createView(modelName: string) {
        this.$emit("registerView", modelName);
    }
    eraseView(nameSlug: string) {
        this.$emit("eraseView", this.getView(nameSlug));
    }

    clientUpdate(data: any) {
        this.$emit("clientUpdate", data);
    }

    changeValueInDropdown(item: IItemFilter) {
        const value = item.value;
        this.$set(this.innerValue, value, !this.innerValue[value]);
        return this.innerValue[value];
    }

    selectAllValueDropdown(section: IFilter) {
        for (const item of section.items) {
            if (item.selected === false) {
                const value = item.value;
                this.innerValue[value] = !this.innerValue[value];
            }
        }
    }

    async init() {
        this.innerLoading = true;
        if (this.lastSelectedView && this.lastSelectedView[this.context || ""]) {
            await this.setSelectedView(this.lastSelectedView[this.context || ""]);
        }
        if (this.lastinnerValue && this.lastinnerValue[this.context || ""]) {
            this.innerValue = this.lastinnerValue[this.context || ""];
        }

        this.innerLoading = false;
    }

    beforeMount() {
        this.init();
    }
}
